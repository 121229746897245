import { useMemo } from 'react';
import { DOWNLOAD_PAGE } from '../../constants';

export default function useDownloadUrl(): string {
  return useMemo(() => {
    let downloadUrl = DOWNLOAD_PAGE;

    if (typeof navigator !== 'undefined') {
      const { userAgent } = navigator;

      const platform = userAgent.includes('iPhone')
        ? '/ios'
        : userAgent.includes('Android')
        ? '/android'
        : userAgent.includes('Mac')
        ? '/mac'
        : userAgent.includes('Win')
        ? '/windows'
        : userAgent.includes('Linux')
        ? '/linux'
        : '';

      downloadUrl += platform;
    }

    return downloadUrl;
  }, []);
}
