import Wordmark from '@components/Wordmark';
import useTranslation from '@helpers/useTranslation';
import cc from 'classcat';
import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import { Container, Content, Footer, Navbar } from 'react-bulma-components';
import * as constants from '../../constants';
import * as styles from './DefaultTemplate.module.scss';
import useDownloadUrl from './useDownloadUrl';

interface DefaultTemplateProps {
  navBarShadow?: boolean;
}

const DefaultTemplate: FC<DefaultTemplateProps> = ({ children, navBarShadow }) => {
  const { t } = useTranslation();

  const downloadUrl = useDownloadUrl();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleBurgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Navbar className={cc({ [styles.hasShadow]: navBarShadow })} active={isMenuOpen}>
        <Container>
          <Navbar.Brand>
            <Navbar.Item to={constants.HOME_PAGE} renderAs={Link} title={t('backToHomePage')}>
              <Wordmark width={72} />
            </Navbar.Item>
            <Navbar.Burger aria-label={t('showMenu')} onClick={handleBurgerClick} />
          </Navbar.Brand>
          <Navbar.Menu>
            <Navbar.Container align="right">
              <Navbar.Item to={constants.PRICING_PAGE} renderAs={Link}>
                {t('pricing')}
              </Navbar.Item>
              <Navbar.Item to={downloadUrl} renderAs={Link}>
                {t('download')}
              </Navbar.Item>
              <Navbar.Item to={constants.LOGIN_PAGE} renderAs={Link}>
                {t('login')}
              </Navbar.Item>
            </Navbar.Container>
          </Navbar.Menu>
        </Container>
      </Navbar>
      {children}
      <Footer>
        <Container>
          <Content textAlign="center">
            <p>&copy; {new Date().getFullYear()} Tiny Nudge LLC</p>
            <p>
              <Link to={constants.PRIVACY_POLICY_PAGE}>Privacy</Link>
            </p>
            <small>
              Pomello is not affiliated, associated, authorized, endorsed by or in any way
              officially connected to Trello, Inc.
              <br />
              Pomello is not affiliated, associated, or endorsed by the Pomodoro Technique&reg; or
              Francesco Cirillo.
              <br />
              Pomodoro Technique&reg; is a registered trademark by Francesco Cirillo.
            </small>
          </Content>
        </Container>
      </Footer>
    </>
  );
};

export default DefaultTemplate;
