import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DefaultTemplate from '@templates/DefaultTemplate';
import PrivacyPolicyView from '@views/PrivacyPolicyView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const PrivacyPage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  return (
    <DefaultTemplate>
      <PageMeta description={t('pages:privacyPolicyDescription')} title={t('privacyPolicy')} />
      <PrivacyPolicyView />
    </DefaultTemplate>
  );
};

export default PrivacyPage;
