import React, { FC } from 'react';
import { Container, Content, Section } from 'react-bulma-components';

const PrivacyPolicyView: FC = () => {
  return (
    <Section>
      <Container>
        <Content>
          <h1>Privacy Policy</h1>
          <p>
            This privacy policy has been compiled to better serve those who are concerned with how
            their 'Personally Identifiable Information' (PII) is being used online. PII, as
            described in US privacy law and information security, is information that can be used on
            its own or with other information to identify, contact, or locate a single person, or to
            identify an individual in context. Please read our privacy policy carefully to get a
            clear understanding of how we collect, use, protect or otherwise handle your Personally
            Identifiable Information in accordance with our website.
          </p>
          <strong>
            What personal information do we collect from the people that visit our blog, website or
            app?
          </strong>
          <p>
            When ordering or registering on our site, as appropriate, you may be asked to enter your
            name, email address or other details to help you with your experience.
          </p>
          <strong>When do we collect information?</strong>
          <p>
            We collect information from you when you register on our site or enter information on
            our site.
          </p>
          <strong>How do we use your information?</strong>
          <p>
            We may use the information we collect from you when you register, make a purchase, sign
            up for our newsletter, respond to a survey or marketing communication, surf the website,
            or use certain other site features in the following ways:
          </p>
          <ul>
            <li>
              To personalize your experience and to allow us to deliver the type of content and
              product offerings in which you are most interested.
            </li>
            <li>To send periodic emails regarding your order or other products and services.</li>
          </ul>
          <strong>How do we protect your information?</strong>
          <p>
            We do not use vulnerability scanning and/or scanning to PCI standards. An external PCI
            compliant payment gateway handles all CC transactions. We do not use Malware Scanning.
          </p>
          <p>
            Your personal information is contained behind secured networks and is only accessible by
            a limited number of persons who have special access rights to such systems, and are
            required to keep the information confidential. In addition, all sensitive/credit
            information you supply is encrypted via Secure Socket Layer (SSL) technology.
          </p>
          <p>
            We implement a variety of security measures when a user enters, submits, or accesses
            their information to maintain the safety of your personal information.
          </p>
          <p>
            All transactions are processed through a gateway provider and are not stored or
            processed on our servers.
          </p>
          <strong>Do we use 'cookies'?</strong>
          <p>We do not use cookies for tracking purposes</p>
          <p>
            You can choose to have your computer warn you each time a cookie is being sent, or you
            can choose to turn off all cookies. You do this through your browser settings. Since
            browser is a little different, look at your browser's Help Menu to learn the correct way
            to modify your cookies.
          </p>
          <p>
            If you turn cookies off, Some of the features that make your site experience more
            efficient may not function properly.
          </p>
          <strong>Third-party disclosure</strong>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your Personally
            Identifiable Information.
          </p>
          <strong>Third-party links</strong>
          <p>We do not include or offer third-party products or services on our website.</p>
          <strong>Google</strong>
          <p>
            Google's advertising requirements can be summed up by Google's Advertising Principles.
            They are put in place to provide a positive experience for users. See more at:&nbsp;
            <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">
              https://support.google.com/adwordspolicy/answer/1316548?hl=en
            </a>
          </p>
          <p>We have not enabled Google AdSense on our site but we may do so in the future.</p>
          <strong>California Online Privacy Protection Act</strong>
          <p>
            CalOPPA is the first state law in the nation to require commercial websites and online
            services to post a privacy policy. The law's reach stretches well beyond California to
            require any person or company in the United States (and conceivably the world) that
            operates websites collecting Personally Identifiable Information from California
            consumers to post a conspicuous privacy policy on its website stating exactly the
            information being collected and those individuals or companies with whom it is being
            shared.
          </p>
          <p>
            See more at:&nbsp;
            <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
            </a>
          </p>
          <strong>According to CalOPPA, we agree to the following:</strong>
          <ul>
            <li>Users can visit our site anonymously.</li>
            <li>
              Once this privacy policy is created, we will add a link to it on our home page or as a
              minimum, on the first significant page after entering our website.
            </li>
            <li>
              Our Privacy Policy link includes the word 'Privacy' and can easily be found on the
              page specified above.
            </li>
            <li>You will be notified of any Privacy Policy changes on our Privacy Policy Page</li>
            <li>Can change your personal information by logging in to your account</li>
          </ul>
          <strong>How does our site handle Do Not Track signals?</strong>
          <p>
            We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a
            Do Not Track (DNT) browser mechanism is in place.
          </p>
          <strong>Does our site allow third-party behavioral tracking?</strong>
          <p>It's also important to note that we do not allow third-party behavioral tracking.</p>
          <strong>COPPA (Children Online Privacy Protection Act)</strong>
          <p>
            When it comes to the collection of personal information from children under the age of
            13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in
            control. The Federal Trade Commission, United States' consumer protection agency,
            enforces the COPPA Rule, which spells out what operators of websites and online services
            must do to protect children's privacy and safety online.
          </p>
          <p>We do not specifically market to children under the age of 13 years old.</p>
          <strong>Fair Information Practices</strong>
          <p>
            The Fair Information Practices Principles form the backbone of privacy law in the United
            States and the concepts they include have played a significant role in the development
            of data protection laws around the globe. Understanding the Fair Information Practice
            Principles and how they should be implemented is critical to comply with the various
            privacy laws that protect personal information.
          </p>
          <strong>
            In order to be in line with Fair Information Practices we will take the following
            responsive action, should a data breach occur:
          </strong>
          <p>We will notify you via email within 7 business days.</p>
          <p>
            We also agree to the Individual Redress Principle which requires that individuals have
            the right to legally pursue enforceable rights against data collectors and processors
            who fail to adhere to the law. This principle requires not only that individuals have
            enforceable rights against data users, but also that individuals have recourse to courts
            or government agencies to investigate and/or prosecute non-compliance by data
            processors.
          </p>
        </Content>
      </Container>
    </Section>
  );
};

export default PrivacyPolicyView;
